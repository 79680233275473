<template>
    <div>
        <div>用户id：{{userinfo.user_id}}</div>
        <div>名称：{{userinfo.user_name}}</div>
        <div>头像{{userinfo.head_img_url}}</div>
        <div>sex：{{userinfo.sex}}</div>
        <div>token：{{userinfo.token}}</div>
    </div>
</template>
<script>
import { setToken , getToken } from '../../util/util'
export default {
    name:'callback',
    data(){
        return{
            userinfo:{},

        }
    },
    mounted(){
        let code = this.$route.query.code;
        // this.$Toast(code)
        console.log('route',code)
        if(code){
            this.$http.post('/user_service/user/get_wechat_info',{code:code}).then(res=>{
                console.log('rffffes',res)
                // const data  = res.data;
                if(res.code == 0){
                    this.userinfo = res.data;
                    setToken('userInfo',userInfo)
                    setTimeout(() => {
                        // this.turn();
                    }, 1);
                }else{
                    // this.$Toast(res)
                //    this.turnIndex();
                }
            }).catch(err=>{
                console.log('er',err);
                // this.$Toast(err)
                // this.turnIndex();
            })
        }
    },
    methods:{
        turn(){
            this.$router.replace({
                path:'/'
            })
        },
        turnIndex(){
            this.$router.replace({
                path:'/'
            })
        }
    }
}
</script>